<template>
  <div v-if="isDataLoadedFromServer">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="700"
    >
      <div class="bg-white poppins">
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Edit Record</h4>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon svg-icon-5">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form id="editForm">
            <div class="h-75px">
              <v-select
                v-model="formData.type_id"
                label="Type"
                item-text="text"
                item-value="index"
                :items="serverData.types"
                clearable
                outlined
                dense
                hide-details
                required
                :error-messages="type_idErrors"
                @input="$v.formData.type_id.$touch()"
                @blur="$v.formData.type_id.$touch()"
              ></v-select>
            </div>
            <div class="h-75px" v-if="isOrganization">
              <v-select
                v-model="formData.organization_id"
                label="Organization"
                item-text="text"
                item-value="index"
                :items="serverData.organization"
                clearable
                outlined
                dense
                hide-details
              ></v-select>
            </div>
            <div class="h-75px" v-else>
              <v-autocomplete
                v-model="formData.client_id"
                label="Client"
                item-text="text"
                item-value="index"
                :items="serverData.clients"
                clearable
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </div>
            <div class="h-75px">
              <v-text-field
                v-model="formData.account_number"
                label="Account number"
                clearable
                outlined
                dense
              ></v-text-field>
            </div>
            <div class="h-75px">
              <v-text-field
                v-model="formData.bank"
                label="Bank"
                clearable
                outlined
                dense
              ></v-text-field>
            </div>
            <div class="h-75px">
              <v-text-field
                v-model="formData.iban"
                label="Iban"
                clearable
                outlined
                dense
              ></v-text-field>
            </div>
            <div class="h-75px">
              <v-text-field
                v-model="formData.currency"
                label="Currency"
                clearable
                outlined
                dense
              ></v-text-field>
            </div>
            <div class="h-75px">
              <v-text-field
                v-model="formData.account_name"
                label="Account name"
                clearable
                outlined
                dense
              ></v-text-field>
            </div>
            <div class="h-75px">
              <v-text-field
                v-model="formData.trn"
                label="TRN"
                clearable
                outlined
                dense
              ></v-text-field>
            </div>
          </form>
          <!--end::Body-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Clear
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      type_id: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      type_id: null,
      organization_id: null,
      client_id: null,
      account_number: null,
      bank: null,
      iban: null,
      currency: null,
      account_name: null,
      trn: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;

      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/organization/bankAccounts/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.formData = response.data.bank_account;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    submitEditForm() {
      this.$v.$touch();
      this.pageLoader(true);
      let data = this.formData;
      ApiService.post("/organization/bankAccounts/update", data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Bank account data has been updated`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      this.$v.$reset();

      this.formData = {
        type_id: null,
        organization_id: null,
        client_id: null,
        account_number: null,
        bank: null,
        iban: null,
        currency: null,
        account_name: null,
        trn: null,
      };
    },
    toggleModal() {
      if (this.dialog) {
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
  },
  computed: {
    isOrganization() {
      return this.formData.type_id === 1;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    type_idErrors() {
      return this.handleFormValidation("type_id", this);
    },
  },
};
</script>
